class DateFunc {

    private yearDiff: number;
    private monthDiff: number;
    private dayDiff: number;
    private dayDiffAll: number;

    constructor(
        start_date: Date | undefined,
        end_date: Date | undefined
    ) {
        this.yearDiff = 0;
        this.monthDiff = 0;
        this.dayDiff = 0;
        this.dayDiffAll = 0;

        if (start_date !== undefined && end_date !== undefined) {
            //経過年
            this.yearDiff = end_date.getFullYear() - start_date.getFullYear();

            //経過月
            this.monthDiff = end_date.getMonth() - start_date.getMonth();
            if (this.monthDiff < 0) {
                this.yearDiff = this.yearDiff - 1;
                this.monthDiff = this.monthDiff + 12;
            }

            //経過日
            this.dayDiff = end_date.getDate() - start_date.getDate();
            if (this.dayDiff < 0) {
                this.monthDiff = this.monthDiff - 1;
                const first_day = new Date(end_date.getFullYear(), end_date.getMonth(), 1);
                let last_month = new Date(first_day.getFullYear(), first_day.getMonth(), 0);
                this.dayDiff = this.dayDiff + last_month.getDate();
            }

            this.dayDiffAll = Math.floor((end_date.getTime() - start_date.getTime()) / 1000 / 60 / 60 / 24);
        }
    }

    get ymd_str() { return this.yearDiff + '年' + this.monthDiff + 'ヶ月' + this.dayDiff + '日' }
    get month_count() {
        const count = this.yearDiff * 12 + this.monthDiff;
        return count + 'ヶ月' + this.dayDiff + '日'
    }
    get week_count() {
        const count = Math.floor(this.dayDiffAll / 7);
        const amari = this.dayDiffAll % 7;
        return count + '週' + amari + '日';
    }
    get date_count() { return this.dayDiffAll + '日' }
}


export default DateFunc;