import React from 'react';
import './App.css';
import CalcAge from './components/CalcAge';
import Tool from './components/Tool';

function App() {
  return (
    <div className="App">
      <Tool>月齢計算1<CalcAge /></Tool>
      <Tool>月齢計算2<CalcAge /></Tool>
    </div>
  );
}

export default App;
