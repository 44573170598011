import React from "react";
import { Fragment } from "react";
import { Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import DateFunc from "../DateFunc";

const CalcAge = () => {

    const y = 2018;
    const m = 4;
    const d = 10;

    const [value_s, setValue_s] = React.useState<moment.Moment | null>(
        moment(new Date(y, m - 1, d)),
    );

    const handleChange_s = (newValue: moment.Moment | null) => {
        setValue_s(newValue);
    };

    const [value_e, setValue_e] = React.useState<moment.Moment | null>(
        moment(new Date()),
    );

    const handleChange_e = (newValue: moment.Moment | null) => {
        setValue_e(newValue);
    };

    const dateFunc = new DateFunc(value_s?.toDate(), value_e?.toDate());

    return (
        <Fragment>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack spacing={2}>
                    <MobileDatePicker
                        label="Start Date"
                        inputFormat="YYYY/MM/DD"
                        value={value_s}
                        onChange={handleChange_s}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <MobileDatePicker
                        label="End Date"
                        inputFormat="YYYY/MM/DD"
                        value={value_e}
                        onChange={handleChange_e}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Stack>
            </LocalizationProvider>
            <Typography>{dateFunc.ymd_str}</Typography>
            <Typography>{dateFunc.month_count}</Typography>
            <Typography>{dateFunc.week_count}</Typography>
            <Typography>{dateFunc.date_count}</Typography>
        </Fragment>
    );
}

export default CalcAge;